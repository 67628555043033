const ApiHelper = {};

ApiHelper.getAllActivities = function(placeId) {
  const placePath = placeId ? '?place=' + placeId : '';

  return $.ajax({
    url: ENV.API_BASE_PATH + '/activities' + placePath,
    contentType: "application/json",
    dataType: 'json',
    success: result => {
      result.forEach(activity => {
        activity.description = ApiHelper.formatIcons(activity.fields['Conteudo/Descricao']?.html || activity.fields['Conteudo/Descricao']);
        activity.start_end_hours = ApiHelper.formatHours(activity.fields['Hora Inicio'], activity.fields['Hora Fim']);
      });
    }
  }).fail( (jqXHR, textStatus, errorThrown) => {
    notie.alert({
      type: 'error',
      text: 'Oops, algo correu mal. ☹️ Tentamos um reload?',
      position: 'top'
    });
    console.error('Request failed (getAllActivities): ', textStatus);
  });
};

ApiHelper.getActivity = function(activityId) {
  return $.ajax({
    url: ENV.API_BASE_PATH + '/activities/' + activityId,
    contentType: 'application/json',
    dataType: 'json',
    success: result => {
      result.description = ApiHelper.formatIcons(result.fields['Conteudo/Descricao']?.html || result.fields['Conteudo/Descricao']);
      result.start_end_hours = ApiHelper.formatHours(result.fields['Hora Inicio'], result.fields['Hora Fim']);
    }
  }).fail( (jqXHR, textStatus, errorThrown) => {
    notie.alert({
      type: 'error',
      text: 'Oops, algo correu mal. ☹️ Tentamos um reload?',
      position: 'top'
    });
    console.error('Request failed: ' + textStatus);
  }).promise();
};

ApiHelper.getActivityUserPhotos = function(activityId) {
  const activityPath = activityId ? '?activity=' + activityId : '?state=Aprovada&max_records=30';

  return $.ajax({
    url: ENV.API_BASE_PATH + '/photos' + activityPath,
    contentType: "application/json",
    dataType: 'json',
    success: result => {
      result.forEach(photos => {
        return photos;
      });
    }
  }).fail( (jqXHR, textStatus, errorThrown) => {
    notie.alert({
      type: 'error',
      text: 'Oops, algo correu mal. ☹️ Tentamos um reload?',
      position: 'top'
    });
    console.error('Request failed: ' + textStatus);
  });
};


ApiHelper.getActivityQrCodeUrl = function(activityId) {
  return ENV.API_BASE_PATH + '/activities/' + activityId + '/qrcode';
}

ApiHelper.getAllPlaces = function() {
  return $.ajax({
    url: ENV.API_BASE_PATH + '/places',
    contentType: 'application/json',
    dataType: 'json',
  }).fail( (jqXHR, textStatus, errorThrown) => {
    notie.alert({
      type: 'error',
      text: 'Oops, algo correu mal. ☹️ Tentamos um reload?',
      position: 'top'
    });
    console.error('Request failed: ' + textStatus);
  });
};


ApiHelper.postCheckIn = function(uid, activityId, activityName, searchParams) {
  return $.ajax({
    type: "POST",
    url: ENV.API_BASE_PATH + '/check-ins',
    dataType: 'json',
    data: {
      uid: uid,
      activity_id: activityId,
      activity_name: activityName,
      utm_source: searchParams.get('utm_source'),
      utm_medium: searchParams.get('utm_medium'),
      utm_campaign : searchParams.get('utm_campaign'),
      user_agent : navigator.userAgent,
      screen: JSON.stringify({
        availHeight: window.screen.availHeight,
        availWidth: window.screen.availWidth,
        colorDepth: window.screen.colorDepth,
        height: window.screen.height,
        orientation: {
          type: window.screen.orientation && window.screen.orientation.type,
          angle: window.screen.orientation && window.screen.orientation.angle
        },
        pixelDepth: window.screen.pixelDepth,
        width: window.screen.width,
        devicePixelRatio: window.devicePixelRatio
      }),
      client_date : new Date(Date.now()).toISOString()
    }
  }).done(() => {
    console.log('posted!');
  }).fail( (jqXHR, textStatus, errorThrown) => {
    notie.alert({
      type: 'error',
      text: 'Oops, algo correu mal. ☹️ Tentamos um reload?',
      position: 'top'
    });
    console.error('Request failed: ' + textStatus);
  }).promise();
};

ApiHelper.getCheckIns = function(uid) {
  return $.ajax({
    url: ENV.API_BASE_PATH + '/check-ins/' + uid,
    contentType: "application/json",
    dataType: 'json',
  }).fail( (jqXHR, textStatus, errorThrown) => {
    console.log('Request failed: ' + textStatus );
  }).promise();
};

ApiHelper.formatHours = function(start_date, end_date) {
  if(!start_date && !end_date)
    return '';

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  return startDate.getHours().pad(2) + ':' + startDate.getMinutes().pad(2) + '—' + endDate.getHours().pad(2) + ':' + endDate.getMinutes().pad(2);
};


ApiHelper.formatIcons = function(text) {
  if(!text)
    return '';

  //External link
  const regexExtLink = /:custom\_website\_logo:/gm;
  const substExtLink = `<i class="fas fa-external-link-square-alt"></i>`;
  text = text.replace(regexExtLink, substExtLink);

  // Facebook
  const regexFb = /:custom\_facebook\_logo:/gm;
  const substFb = `<i class="fab fa-facebook-square"></i>`;
  text = text.replace(regexFb, substFb);

  //Instagram
  const regexInsta = /:custom\_instagram\_logo:/gm;
  const substInsta = `<i class="fab fa-instagram-square"></i>`;
  text = text.replace(regexInsta, substInsta);

  //Email
  const regexEmail = /:custom\_email\_logo:/gm;
  const substEmail = `<i class="fas fa-envelope"></i>`;
  text = text.replace(regexEmail, substEmail);

  return text;
};
